import React from 'react'
import { useBasicSettings } from '../hooks/useBasicSettings'
import { BasicTemplate } from '../components/templates/basicTemplate'
import { ThankyouTemplate } from '../components/templates/thankyouTemplate'

const ThankyouPage = () => {
  const { basicSettings } = useBasicSettings()

  return (
    <BasicTemplate basicSettings={basicSettings}>
      <ThankyouTemplate basicSettings={basicSettings} />
    </BasicTemplate>
  )
}

export default ThankyouPage
