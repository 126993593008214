import React from 'react'
import styled from 'styled-components'
import { Center as _Center } from '../layout'
import { BasicSettings } from '../../types/basicSettings'

interface Props {
  basicSettings: BasicSettings
}

export const ThankyouTemplate = (props: Props) => {
  const { thankyouTitle, thankyouMessage } = props.basicSettings

  return (
    <Center>
      <ThankyouCard>
        <h2>{thankyouTitle}</h2>
        <div>{thankyouMessage}</div>
      </ThankyouCard>
    </Center>
  )
}

const Center = styled((props) => <_Center {...props} />)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const ThankyouCard = styled.div`
  h2 {
    margin-bottom: 10px;
  }
`
